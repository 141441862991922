import { createSlice } from "@reduxjs/toolkit";
import ProjectModel from "model/Project.model";
import { setProjectListReducer, setSelectedProjectReducer } from "slice/reducer/ProjectReducer";

export interface ProjectState {
  selectedProject: ProjectModel | null;
  projectListData: ProjectModel[];
}

const initialState: ProjectState = {
  selectedProject: null,
  projectListData: [],
};

const cartSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setSelectedProject: setSelectedProjectReducer,
    setProjectList: setProjectListReducer,
  },
});

export const { setSelectedProject, setProjectList } = cartSlice.actions;

export default cartSlice.reducer;
