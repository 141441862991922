export enum ScreenId {
  Loading = 'loading',
  Configuration = 'configuration',
  Home = 'home',
  Session = 'session',
  Project = 'project',
  Chat = 'chat',
}

//Routes
export const linkPageConfiguration = '/';
export const linkPageHome = '/home';
export const linkPageProject = '/project';
export const linkPageSession = '/session';
export const linkPageChat = '/chat';
