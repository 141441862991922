import type { PayloadAction } from '@reduxjs/toolkit';
import ConversationModel from 'model/Conversation.model';
import { SessionState } from 'slice/SessionSlice';

export const setSelectedConversationReducer = (
  state: SessionState,
  action: PayloadAction<ConversationModel | null>
) => {
  return {
    ...state,
    selectedConversation: action.payload,
  };
};

export const setConversationListReducer = (
  state: SessionState,
  action: PayloadAction<ConversationModel[]>
): SessionState | void => {
  return {
    ...state,
    conversations: action.payload,
  };
};

export const addConversationToTheBeginningReducer = (
  state: SessionState,
  action: PayloadAction<ConversationModel>
): SessionState | void => {
  return {
    ...state,
    conversations: [action.payload, ...state.conversations],
  };
};

export const appendANewConversationReducer = (
  state: SessionState,
  action: PayloadAction<ConversationModel>
): SessionState | void => {
  return {
    ...state,
    conversations: [...state.conversations, action.payload],
  };
};

export const appendMoreConversationsReducer = (
  state: SessionState,
  action: PayloadAction<ConversationModel[]>
): SessionState | void => {
  return {
    ...state,
    conversations: [...state.conversations, ...action.payload],
  };
};

export const updateConversationReducer = (
  state: SessionState,
  action: PayloadAction<ConversationModel>
): SessionState | void => {
  const index = state.conversations.findIndex((conversation) => conversation.uuid === action.payload.uuid);
  if (index >= 0) state.conversations[index] = { ...state.conversations[index], ...action.payload };
};

export const updateNewConversationReducer = (
  state: SessionState,
  action: PayloadAction<ConversationModel>
): SessionState | void => {
  const index = state.conversations.findIndex((conversation) => conversation.name === 'New Session');
  if (index >= 0) state.conversations[index] = { ...state.conversations[index], ...action.payload };
  else state.conversations.unshift(action.payload);
};

export const setIsMessageStreamingReducer = (state: SessionState, action: PayloadAction<boolean>) => {
  state.isMessageStreaming = action.payload;
};

export const setIsChatHistoryLoadingReducer = (state: SessionState, action: PayloadAction<boolean>) => {
  state.isChatHistoryLoading = action.payload;
};

export const setIsStartedAnalyzingReducer = (state: SessionState, action: PayloadAction<boolean>) => {
  state.isStartedAnalyzing = action.payload;
};

export const setReceivedSummaryReducer = (state: SessionState, action: PayloadAction<boolean>) => {
  state.receivedSummary = action.payload;
};
