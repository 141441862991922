import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { css, cx } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { getBackendSrv, getTemplateSrv } from '@grafana/runtime';
import { time } from 'console';

interface Props extends PanelProps<{}> {}

const getStyles = () => {
  return {
    wrapper: css`
      font-family: Open Sans;
      position: relative;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    `,
    button: css`
      padding: 10px;
      border-radius: 4px;
      border: none;
      background-color: #007bff;
      color: white;
      cursor: pointer;
    `,
  };
};

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const styles = useStyles2(getStyles);
  const [currentDashboardId, setCurrentDashboardId] = useState<string | null>(null);
  const [timeRange, setTimeRange] = useState(null);
  const [variables, setVariables] = useState(null);

  // Get current URL
  // If you're in the browser:
  const currentUrl = window.location.href;

  useEffect(() => {
    const templateSrv = getTemplateSrv();
    const variablesData = templateSrv.getVariables();
    setVariables(variablesData);
    console.log('variables:>> ', variablesData);
    console.log('data:>> ', data);
    setTimeRange(data?.timeRange?.raw);
    console.log('options:>> ', options);
    setCurrentDashboardId(data?.request?.dashboardUID);
  }, []);

  const fetchPanelsDetails = async () => {
    if (!currentDashboardId) {
      console.warn('No current dashboard ID available.');
      return;
    }

    try {
      const response = await getBackendSrv().get(`/api/dashboards/uid/${currentDashboardId}`);
      console.log('current dashboard data:>> ', response);

      const panels = response.dashboard.panels;
      if (!panels || !Array.isArray(panels)) {
        console.error('No panels found in dashboard');
        return;
      }

      // Get panel #3
      const targetPanel = panels[14];
      if (targetPanel && targetPanel.targets) {
        fetchPanelData(targetPanel.targets);
      } else {
        console.error('Target panel or its targets not found');
      }
    } catch (error) {
      console.error('Error fetching panels:', error);
    }
  };

  const fetchPanelData = async (targets: any[]) => {
    console.log('in fetchPanelData');

    try {
      if (!Array.isArray(targets) || targets.length === 0) {
        console.error('No valid targets provided');
        return;
      }

      let dataSourceType;
      const queries = [];
      for (const element of targets) {
        dataSourceType = element.datasource.type;
        let data = { ...element };

        // Update the object
        const updatedObj = updateObjectVariables(data, variables);
        queries.push(updatedObj);
      }

      console.log('Query payload:', {
        queries,
        from: timeRange ? timeRange?.from : 'now-6h',
        to: timeRange ? timeRange?.to : 'now',
      });

      const response = await getBackendSrv().post(`/api/ds/query?ds_type=${dataSourceType}`, {
        queries,
        from: timeRange ? timeRange?.from : 'now-6h',
        to: timeRange ? timeRange?.to : 'now',
      });

      console.log('Panel Data:', response);
    } catch (error) {
      console.error('Error fetching panel data:', error);
    }
  };

  function convertVariablesArrayToObject(variablesArray) {
    return variablesArray.reduce((acc, variable) => {
      acc[variable.name] = variable.current.value;
      return acc;
    }, {});
  }

  // Function to replace variables in a string
  function replaceVariables(str, variables) {
    // Replace ${var} format
    str = str.replace(/\${(\w+)}/g, (match, varName) => {
      return variables[varName] || match;
    });

    // Replace $var format
    str = str.replace(/\$(\w+)/g, (match, varName) => {
      return variables[varName] || match;
    });

    return str;
  }

  // Function to update object values recursively
  function updateObjectVariables(obj, variablesArray) {
    // First convert variables array to object
    const variables = convertVariablesArrayToObject(variablesArray);
    const newObj = { ...obj };

    Object.keys(newObj).forEach((key) => {
      if (typeof newObj[key] === 'object' && newObj[key] !== null) {
        // Recursively update nested objects
        newObj[key] = updateObjectVariables(newObj[key], variablesArray);
      } else if (typeof newObj[key] === 'string') {
        // Replace variables in strings
        newObj[key] = replaceVariables(newObj[key], variables);
      }
    });

    return newObj;
  }

  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
      <button className={styles.button} onClick={fetchPanelsDetails}>
        Fetch Panels Details
      </button>

      <button
        className={styles.button}
        onClick={() =>
          getBackendSrv()
            .get('/api/datasources')
            .then((response) => console.log('Datasources:', response))
        }
      >
        Fetch Data Sources
      </button>
    </div>
  );
};
