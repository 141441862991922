import type { PayloadAction } from "@reduxjs/toolkit";
import ProjectModel from "model/Project.model";
import { ProjectState } from "slice/ProjectSlice";

export const setSelectedProjectReducer = (
  state: ProjectState,
  action: PayloadAction<ProjectModel>
) => {
  state.selectedProject = action.payload;
};

export const setProjectListReducer = (
  state: ProjectState,
  action: PayloadAction<ProjectModel[]>
) => {
  state.projectListData = action.payload;
};
