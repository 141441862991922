import { PanelProps } from '@grafana/data';
import { getTemplateSrv } from '@grafana/runtime';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { AppOptions } from 'types';
import store from './store';
import { ScreenId } from 'routes';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import * as routes from 'routes';
import './i18n';
import Loader from 'component/Loader/Loader';
import PanelResize from 'component/PanelResize/PanelResize';

const ChatScreen = lazy(() => import('views/ChatScreen'));
const ConfigurationScreen = lazy(() => import('views/ConfigurationScreen'));
const HomeScreen = lazy(() => import('views/HomeScreen'));
const SessionScreen = lazy(() => import('views/SessionScreen'));

interface Props extends PanelProps<AppOptions> {}

export const App: React.FC<Props> = ({ options, data, width, height, fieldConfig, id }) => {
  const [currentScreen, setCurrentScreen] = useState<ScreenId>(ScreenId.Configuration);
  const [currentDashboardId, setCurrentDashboardId] = useState<string | null>(null);
  const [timeRange, setTimeRange] = useState<any>(null);
  const [variables, setVariables] = useState<any>(null);

  useEffect(() => {
    const templateSrv = getTemplateSrv();
    const variablesData = templateSrv.getVariables();
    setVariables(variablesData);
    setTimeRange(data?.timeRange?.raw);
  }, [data]);

  const onNavigate = (screen: ScreenId) => {
    setCurrentScreen(screen);
  };

  const renderScreen = () => {
    console.log('renderScreen:>>', width, height);
    // Check for width and height constraints
    if (width < 360 || height < 600) {
      return <PanelResize />;
    }
    switch (currentScreen) {
      case ScreenId.Configuration:
        return (
          <ConfigurationScreen
            onNavigate={onNavigate}
            currentDashboardId={data?.request?.dashboardUID ? data?.request?.dashboardUID : ''}
          />
        );
      case ScreenId.Home:
        return <HomeScreen height={height} width={width} onNavigate={onNavigate} />;
      case ScreenId.Session:
        return <SessionScreen height={height} width={width} onNavigate={onNavigate} />;
      case ScreenId.Chat:
        return <ChatScreen height={height} onNavigate={onNavigate} />;
      default:
        return (
          <ConfigurationScreen
            onNavigate={onNavigate}
            currentDashboardId={data?.request?.dashboardUID ? data?.request?.dashboardUID : ''}
          />
        );
    }
  };

  // const renderRouter = () => {
  //   return (
  //     <Routes>
  //       <Route path={routes.linkPageConfiguration} element={<ConfigurationScreen onNavigate={onNavigate} />} />
  //       <Route
  //         path={routes.linkPageHome}
  //         element={<HomeScreen height={height} width={width} onNavigate={onNavigate} />}
  //       />
  //       <Route
  //         path={routes.linkPageProject}
  //         element={<ProjectScreen height={height} width={width} onNavigate={onNavigate} />}
  //       />
  //       <Route
  //         path={routes.linkPageSession}
  //         element={<SessionScreen height={height} width={width} onNavigate={onNavigate} />}
  //       />
  //       <Route path={routes.linkPageChat} element={<ChatScreen height={height} onNavigate={onNavigate} />} />
  //     </Routes>
  //   );
  // };
  return (
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
        <div style={{ width, height }}>{renderScreen()}</div>
      </Suspense>
    </Provider>
  );
};
