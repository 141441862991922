import { createSlice } from '@reduxjs/toolkit';
import { UserModel } from 'model/User.model';
import { setShouldNavigateToHomeReducer, setUserInfoReducer } from './reducer/UserInfoReducer';

export interface UserInfoState {
  userInfo: UserModel | null;
  shouldNavigateToHome: boolean;
}

const initialState: UserInfoState = {
  userInfo: null,
  shouldNavigateToHome: true,
};

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo: setUserInfoReducer,
    setShouldNavigateToHome: setShouldNavigateToHomeReducer,
  },
});

export const { setUserInfo, setShouldNavigateToHome } = userInfoSlice.actions;

export default userInfoSlice.reducer;
