import type { PayloadAction } from '@reduxjs/toolkit';
import { UserModel } from 'model/User.model';
import { UserInfoState } from 'slice/UserSlice';

export const setUserInfoReducer = (state: UserInfoState, action: PayloadAction<UserModel>) => {
  state.userInfo = action.payload;
};

export const setShouldNavigateToHomeReducer = (state: UserInfoState, action: PayloadAction<boolean>) => {
  state.shouldNavigateToHome = action.payload;
};
