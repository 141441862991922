import { createSlice } from '@reduxjs/toolkit';
import ConversationModel from 'model/Conversation.model';
import {
  addConversationToTheBeginningReducer,
  appendANewConversationReducer,
  appendMoreConversationsReducer,
  setConversationListReducer,
  setIsChatHistoryLoadingReducer,
  setIsMessageStreamingReducer,
  setIsStartedAnalyzingReducer,
  setReceivedSummaryReducer,
  setSelectedConversationReducer,
  updateConversationReducer,
  updateNewConversationReducer,
} from './reducer/Session.reducer';

export interface SessionState {
  conversations: ConversationModel[];
  selectedConversation: ConversationModel | null;
  isMessageStreaming: boolean;
  isChatHistoryLoading: boolean;
  isStartedAnalyzing: boolean;
  receivedSummary?: boolean;
}

const initialState: SessionState = {
  conversations: [],
  selectedConversation: new ConversationModel(),
  isMessageStreaming: false,
  isChatHistoryLoading: false,
  isStartedAnalyzing: false,
  receivedSummary: false,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSelectedConversation: setSelectedConversationReducer,
    setConversationList: setConversationListReducer,
    addConversationToTheBeginning: addConversationToTheBeginningReducer,
    appendANewConversation: appendANewConversationReducer,
    appendMoreConversations: appendMoreConversationsReducer,
    updateNewConversation: updateNewConversationReducer,
    updateConversation: updateConversationReducer,
    setIsMessageStreaming: setIsMessageStreamingReducer,
    setIsChatHistoryLoading: setIsChatHistoryLoadingReducer,
    setIsStartedAnalyzing: setIsStartedAnalyzingReducer,
    setReceivedSummary: setReceivedSummaryReducer,
  },
});

export const {
  setConversationList,
  addConversationToTheBeginning,
  appendANewConversation,
  appendMoreConversations,
  updateNewConversation,
  updateConversation,
  setSelectedConversation,
  setIsMessageStreaming,
  setIsChatHistoryLoading,
  setIsStartedAnalyzing,
  setReceivedSummary,
} = sessionSlice.actions;

export default sessionSlice.reducer;
