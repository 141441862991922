import MessageModel from 'model/Message.model';
import ProjectModel from './Project.model';
import { SessionType } from 'util/CommonConstant';
import IncidentInfoModel from './IncidentInfo.model';

class ConversationModel {
  uuid: string | null;
  name: string | null;
  messages: MessageModel[];
  project: ProjectModel | null;
  followUps: string[];
  isLive?: boolean = false;
  createdTime?: Date;
  lastUpdated?: Date;
  sessionType?: SessionType;
  incidentInfo?: IncidentInfoModel;

  constructor(
    uuid: string | null = null,
    name: string | null = null,
    messages: MessageModel[] = [],
    project: ProjectModel | null = null,
    followUps: string[] = []
  ) {
    this.uuid = uuid;
    this.name = name;
    this.messages = messages;
    this.project = project;
    this.followUps = followUps;
  }
}

export default ConversationModel;
