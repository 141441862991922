import React from 'react';
import logo from 'assets/img/logo.svg';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const PanelResize: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full text-center text-gray-400 h-full">
      <div className="flex flex-col p-4 items-center">
        <div className="flex flex-col items-center">
          <span className="cursor-not-allowed mt-6 mb-6">
            <img src={logo} alt="logo" className="h-20" />
          </span>
          <ExclamationTriangleIcon className={`h-8 w-8 text-primary-red-pigment-800 cursor-not-allowed`} />
          <h3 className="mt-2 text-primary-red-pigment-700 font-semibold text-markdown-h3">
            Panel requires more space to display content. Increase panel size for optimal viewing.
          </h3>
        </div>
      </div>
    </div>
  );
};

export default PanelResize;
