export const LOCAL_STORAGE = {
  USER_INFO: 'userInfo',
  CURRENT_USER: 'currentUser',
  DATASOURCE_LIST: 'datasourceList',
  PROJECT_LIST: 'projects',
  CONFIGURATION_DATA: 'configurationData',
};

export const LANGUAGES = {
  EN: 'en',
  FR: 'fr',
};
export const LOCALE = {
  IN: 'en-IN',
  US: 'en-US',
};
export const DEFAULT_LOCALE_CONST = {
  LANG: 'defaultLanguage',
  CURRENCY: 'defaultCurrency',
  LOCALE: 'defaultLocale',
};
export const ROUNDING_MODE = {
  ROUND: Math.round,
  FLOOR: Math.floor,
  CEIL: Math.ceil,
};

export const INPUT_STYLE_TYPE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};

export const INPUT_NUMBER_STYLE_TYPE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};

export const AUTOCOMPLETE_STYLE_TYPE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};

export const OTP_STYLE_TYPE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};

export const LABEL_STYLE_TYPE = {
  PRIMARY: 'PRIMARY',
  RIGHT: 'RIGHT',
};

export const DROPDOWN_STYLE_TYPE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  TERTIARY: 'TERTIARY',
  QUATERNARY: 'QUATERNARY',
};

export const INPUT_ERROR_TYPE = {
  BORDER: 'BORDER',
  DEFAULT: 'DEFAULT',
};

export const LIMIT_TYPE = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

export const CHECKBOX_STYLE_TYPE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};
export const TOAST_VARIANT = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  TERTIARY: 'TERTIARY',
  QUATERNARY: 'QUATERNARY',
};
export const TOAST_STYLE_TYPE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  TERTIARY: 'TERTIARY',
  QUATERNARY: 'QUATERNARY',
};

export const BUTTON_STYLE_TYPE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};

export const COLOR_STYLE_TYPE = {
  RED: 'RED',
  GRAY: 'GRAY',
  PLUM: 'PLUM',
};

export const LOADER_POSITION = {
  RIGHT: 'right',
  LEFT: 'left',
};
export const MIME_TYPE = {
  JSON: 'application/json',
  PLAIN: 'text/plain',
};

export const IS_ROUTE_GUARD = {
  Y: 'Y',
  N: 'N',
};

// ENVIRONMENT VARIABLE CONSTANTS
export const ENV_VAR = {
  BASE_API_URL: 'BASE_API_URL',
  ENABLE_PWA_MODE: 'ENABLE_PWA_MODE',
  IS_ACL_ENABLED: 'IS_ACL_ENABLED',
  IS_ROUTE_GUARD: 'IS_ROUTE_GUARD',
  SHOW_LOGS: 'SHOW_LOGS',
  GOOGL_MAP_KEY: 'GOOGL_MAP_KEY',
  SHOULD_PERSIST: 'SHOULD_PERSIST',
  PERSIST_STORAGE: 'PERSIST_STORAGE',
  PERSIST_STORAGE_SECRET: 'PERSIST_STORAGE_SECRET',
  MICROSOFT_CLIENT_ID: 'MICROSOFT_CLIENT_ID',
  MICROSOFT_TENANT_ID: 'MICROSOFT_TENANT_ID',
  MASTER_SUBSCRIPTION_AGREEMENT_LINK: 'MASTER_SUBSCRIPTION_AGREEMENT_LINK',
  GITHUB_APP_INSTALLATION_LINK: 'GITHUB_APP_INSTALLATION_LINK',
  ENABLE_CHANGE_API_URL: 'ENABLE_CHANGE_API_URL',
};

export enum PERSIST_STORAGES {
  INDEX_DB = 'INDEX_DB',
  LOCAL_STORAGE = 'LOCAL_STORAGE',
  SESSION_STORAGE = 'SESSION_STORAGE',
  CUSTOM = 'CUSTOM',
}

export enum STEP_STATUS {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  PENDING = 'pending',
  FAILED = 'failed',
}
