import { combineReducers } from 'redux';
import ProjectReducer from 'slice/ProjectSlice';
import SessionReducer from 'slice/SessionSlice';
import UserInfoReducer from 'slice/UserSlice';

const rootReducer = combineReducers({
  project: ProjectReducer,
  session: SessionReducer,
  userInfo: UserInfoReducer,
});

export default rootReducer;
