import React from 'react';
import loadingJson from 'assets/img/lottie/Loading.json';
import Lottie from 'react-lottie-player';

interface ILoaderProps {
  loading?: boolean; // Indicates whether the loader should be displayed
  color?: string; // The color of the loader
  css?: string; // Additional CSS styles for the loader
  width?: number; // The width of the loader
  height?: number; // The height of the loader
  speedMultiplier?: number; // The speed multiplier for the loader animation
}

// This component displays a loader animation
function LoaderWithoutMemo(props: ILoaderProps) {
  const { width, height } = props;

  return (
    <div className="grid h-full place-items-center">
      {/* Uncomment the following lines to use FadeLoader */}
      {/* <FadeLoader
        loading={loading}
        color={"#ff0000"}
        css={css}
        speedMultiplier={speedMultiplier}
      /> */}

      {/* This component displays the loader animation using Lottie */}
      <Lottie loop animationData={loadingJson} play style={{ width: width ?? 120, height: height ?? 120 }} />
    </div>
  );
}

// Memoize the Loader component to improve performance
const Loader = React.memo(LoaderWithoutMemo);

export default Loader;
