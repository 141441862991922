const ALL_CONST = {
  welcome: "Welcome to Viamagus' Kaiju React Boilerplate!",
  message: " Hello!! This is Home - VIEW-1!",
  "label.view1.post_heading": "Post Heading",
  "label.view1.post_description": "Post Description",
  "label.view2.user_name": "Enter Name",
  "label.user_name": "Enter Name",
  "message.success": "Enter Name",
  "label.product.name": "Hawkeye",
  "label.text.input.email": "Email",
  "label.text.input.password": "Password",
  // "validator.mandatory": "This is a mandatory field",
  "component.button.save": "Save",
  "component.button.submit": "Submit",
  "component.button.back": "Back",
  "component.button.backToConnection": "Back to connections",
  "component.button.next": "Next",
  "component.button.login": "Login",
  "component.button.signUp": "Sign up",
  "component.button.cancel": "Cancel",
  "component.button.connect": "Connect",
  "component.button.runPrompt": "Run Prompt",
  "component.button.reload": "Reload",
  "component.button.refresh": "Refresh",
  "component.button.previous": "Previous",
  "component.button.skip": "Skip",

  "validator.email": "Invalid",
  "validator.mandatory": "Mandatory",

  //-------------Login Page-------------//
  "label.text.login.input.email": "Email *",
  "label.text.login.input.password": "Password *",
  "label.text.login.welcomeNote": "Get started",
  "label.text.login.welcomeNote.with": "with",
  "label.text.login.welcomeNote.bottom1":
    " Welcome to Hawkeye by NeuBird. Get real-time generative analytics on your business data resources.",
  "label.text.login.welcomeNote.bottom2": " Click",
  "label.text.login.welcomeNote.bottom3": "for a demo on how it works.",
  "label.text.login.loginHeading": "Login to Hawkeye",
  "label.text.login.input.placeholder.enterPassword": "Enter Password",
  "label.text.login.input.button.forgotPassword": "Forgot Password?",
  "label.text.login.button.loginWithMicrosoft": "Login with Microsoft",
  "label.text.login.button.loginWithGoogle": "Login with Google",
  "label.text.login.button.forgotPassword": "Forgot Password? contact",
  "label.text.login.button.adminEmail": "admin@neubird.ai",
  "label.text.login.orStatement": "Or Login with",
  "label.text.login.text.doNotHaveAccount":
    "Don't have an account? Please ask for an invitation link from your Admin to join.",
  "label.text.login.text.forgotDialog.title": "It's OK!",
  "label.text.login.text.forgotDialog.description1": "Email us at ",
  "label.text.login.text.forgotDialog.description2": " and we'll help you log in ASAP",
  "label.text.login.text.forgotDialog.button": "Copy email",

  //-------------Signup Page-------------//
  "label.text.signUp.welcomeNote":
    "Join your team on Hawkeye by NeuBird and \n harness real-time generative analytics for \n instant insights into your organization's data.",
  "label.text.signUp.heading": "Sign Up",
  "label.button.signUp": "Sign Up",
  "label.text.signUp.firstName": "First Name*",
  "label.text.signUp.lastName": "Last Name*",
  "label.text.signUp.email": "Email*",
  "label.text.signUp.password": "Password*",
  "label.text.signUp.newPassword": "New Password*",
  "label.text.signUp.confirmPassword": "Confirm Password*",

  //-------Password----------//
  "label.text.password.header": "Password strength",
  "label.text.password.weak": "Weak",
  "label.text.password.good": "Good",
  "label.text.password.strong": "Strong",
  "label.text.password.suggestions": "Suggestions",
  "label.text.password.characters": "At least 8 characters",
  "label.text.password.smallLetter": "At least 1 lowercase",
  "label.text.password.capitalLetter": "At least 1 uppercase",
  "label.text.password.number": "At least 1 number",
  "label.text.password.specialChar": "At least 1 special character",

  //-------------JoinToday Page-------------//
  "label.text.joinToday.welcomeNote":
    "Welcome to Hawkeye by Neubird. Get real-time generative\nanalytics on your business data sources.",
  "label.text.joinToday.heading": "Join Hawkeye today",
  "label.button.joinToday": "Send Activation Email",
  "label.text.joinToday.firstName": "First Name*",
  "label.text.joinToday.lastName": "Last Name*",
  "label.text.joinToday.email": "Email*",
  "label.text.joinToday.password": "Password*",
  "label.text.joinToday.confirmPassword": "Confirm Password*",
  "label.text.joinToday.button": "Join Now",
  "label.text.joinToday.footerText":
    "A verification email will be sent to your inbox if the provided email ID is correct.",
  "label.text.joinToday.checkInfo.title": "Check your inbox!",
  "label.text.joinToday.checkInfo.description":
    "You should have received an email with the verification link.\nYou can close this tab.",
  "label.text.joinToday.checkInfo.button": "Resend email",
  "label.text.joinToday.login.button": "Go to login",
  "label.text.joinToday.login.iAgree": "I agree to ",
  "label.text.joinToday.login.iAgree.label": "Master Subscription Agreement",
  "label.text.joinToday.infoDialog.title": "Invalid Link",
  "label.text.joinToday.infoDialog.description":
    "The link appears to be invalid. Reach out to your organization's admin for assistance.",
  "label.text.joinToday.infoDialog.button": "Back to Login",
  "label.text.joinToday.successContainer.title": "Welcome to Hawkeye!",
  "label.text.joinToday.successContainer.description":
    "Your account is ready. Log in now to access real-time analytics.",

  //-------------Side navbar-------------//
  "label.text.sideNav.home": "Home",
  "label.text.sideNav.projects": "Projects",
  "label.text.sideNav.session": "Session",
  "label.text.sideNav.sessions": "Sessions",
  "label.text.sideNav.chat": "Chat",
  "label.text.sideNav.resources": "Connections",
  "label.text.sideNav.dashboard": "Dashboard",
  "label.text.sideNav.prompt": "Prompt",
  "label.text.sideNav.template": "Template",
  "label.text.sideNav.teams": "Teams",
  "label.text.sideNav.users": "Users",
  "label.text.sideNav.reports": "Reports",

  //-------------Home Page-------------//
  "label.text.home.title": "Hello, I'm Hawkeye",
  "label.text.home.connection.welcomeNote":
    "I'm your IT operations assistant.\nTo help me get started, click the button below.",
  "label.text.home.connectionsNotSynced.welcomeNote":
    "Some connections are still syncing...\nTo check the status, head over to the Connections page.",
  "label.button.home.connection.button.connectResources": "Connect Cloud",
  "label.button.home.project.welcomeNote":
    "Integrate newly added connections into your project.\n To get started, click the button below.",
  "label.button.home.project.button.newProject": "New Project",
  "label.button.home.allAdded.welcomeNote":
    "Great! All connections have been synced.\nYou can now start a session within your project.\nTo begin, head over to the Projects page.",
  "label.button.home.projectsNotReady.welcomeNote":
    "I am preparing your projects before you can start working on it.\nTo check the status, head over to the Projects page",
  "label.button.home.connectionsNotSyncedAndProjectsNotReady.welcomeNote":
    "I am preparing your projects before you can start working on it.\nTo check the status, head over to the Projects page",
  "label.button.home.allAdded.button.goToProjects": "Go to Projects",
  "label.button.home.allAdded.button.goToConnections": "Go to Connections",

  //-------------Resources List-------------//
  "label.text.resourcesList.title": "Connections",
  "label.text.resourcesList.connectAccount": "New Connection",
  "label.text.resourcesList.connectViaYaml": "Connect via YAML",
  "label.text.resourcesList.noConnections.title": "No connections here... yet.",
  "label.text.resourcesList.noConnections.description":
    "Contact your org administrator if this is an unexpected error",
  "label.text.resourcesList.noConnections.button": "New connection",
  "label.text.resourcesList.error.title": "Could not load connections.",
  "label.text.resourcesList.delete.title": "Are you sure?",
  "label.text.resourcesList.delete.description":
    "You'll have to set up this\n connection to use it again.",
  "label.text.resourcesList.error.oneConnection.message":
    "Only one connection can be established at this time.",

  //-------------Connections - create new account-------------//
  "label.text.connectAccount.title": "Connect New Account",
  "label.text.connectAccount.button.notListed": "Service provider not listed",
  "label.text.createConnection.title.newConnection": "New Connections",
  "label.text.createConnection.breadcrumb.home": "Home",
  "label.text.createConnection.breadcrumb.connections": "Connections",
  "label.text.createConnection.stepper.selectService": "Select Service",
  "label.text.createConnection.stepper.credentials": "Enter Credentials",
  "label.text.createConnection.selectService.linkButton": "Service provider not listed?",
  "label.text.createConnection.success.title": "Account ready to use!",
  "label.text.createConnection.success.description":
    "Hawkeye has completed reading the resources from this account.\nNow you can use these resources for your projects.",
  "label.text.createConnection.success.button.newConnection": "New connection",
  "label.text.createConnection.success.button.setUpProject": "Set up a project",

  //-------------Form-------------//
  "label.text.createAccount.form.name": "Name *",
  "label.text.createAccount.form.description": "Description",

  //-------------Resources connect new account form-------------//
  "label.text.connectAccount.form.connect": "Connect ",
  "label.text.connectAccount.form.account": "Account",
  "label.text.connectAccount.button.connect": "Connect",
  "label.text.connectAccount.success.title": "Account Connected",
  "label.text.connectAccount.button.viewResources": "View Connections",
  "label.text.connectAccount.button.addAnotherAccount": "Add Another Account",
  "label.text.connectAccount.button.setUpProject": "Set up a project",
  "label.text.connectAccount.button.goToProjects": "Go to Projects",
  "label.text.connectAccount.or": "OR",
  "label.text.datasourceList.editDatasource": "Edit Connection",

  //-------------Application resources-------------//
  "label.text.applicationResources.title": "Application Sources",
  "label.text.applicationResources.filter": "Filter",
  "label.text.applicationResources.resourceVisualization": "Resource Visualization",
  "label.text.applicationResources.table.title.applicationResources": "Resource Visualization",
  "label.text.applicationResources.table.title.region": "Region",

  //-------------Project List-------------//
  "label.text.projectList.title": "Projects",
  "label.text.projectList.editProject": "Edit project",
  "label.text.projectList.addCardTitle": "Create New Project",
  "label.text.projectList.newProject": "New Project",
  "label.text.projectList.form.projectName": "Project name*",
  "label.text.projectList.form.projectDetails": "Project details",
  "label.text.projectList.toast.message":
    "Project has been moved to bin. It will automatically be deleted after 30days",
  "label.text.projectList.dialog.headerText": "Are you sure?",
  "label.text.projectList.dialog.content":
    "All sessions and prompt templates in this project will be deleted. This action cannot be undone",
  "label.text.projectList.dialog.button.delete": "Yes, Delete",
  "label.text.projectList.dialog.button.noKeepIt": "No, Keep it",
  "label.text.projectList.noConnections.section.title": "Cannot create a project",
  "label.text.projectList.noConnections.section.description":
    "You must have at least <strong>1 active connection</strong> \nbefore creating a project.",
  "label.text.projectList.noConnections.section.button": "Go to connections",
  "label.text.projectList.breadcrumb.home": "Home",
  "label.text.projectList.noProjects.title": "No projects here... yet.",
  "label.text.projectList.noProjects.description":
    "No projects available.\nIf this is unexpected, please contact your organization administrator.",
  "label.text.projectList.delete.description": "The project will be deleted permanently",
  "label.text.projectList.delete.button": "Delete",
  "label.text.projectList.errorState.title": "Could not load projects",
  "label.text.projectList.notReady.message":
    'You can start a session only once project status is "Ready"',
  "label.text.projectList.delete.error.message": "Error deleting the project.",
  "label.text.projectList.refresh.error.message": "Error refreshing the project.",
  "label.text.projectList.addNew.error.limitExceeded":
    "You can only add up to 7 projects at the moment.",

  //-------------Create Project-------------//
  "label.text.createProject.title": "Create a Project",
  "label.text.createProject.newProject": "New Project",
  "label.text.createProject.button.connectData": "Connect Data",
  "label.text.createProject.dialog.button.cancel": "Yes, Cancel",
  "label.text.createProject.button.startSession": "Start a session",
  "label.text.createProject.button.backToProjects": "Back to Projects",
  "label.text.createProject.button.goToConnections": "Go to Connections",
  "label.text.createProject.button.addTeamMembers": "Add team members",
  "label.text.createProject.projectCreate": "Project-{{name}} successfully created!",
  "label.text.createProject.dialog.cancelProject": "Leave without saving?",
  "label.text.createProject.dialog.content":
    "You will loose your progress if you\nchoose to go back now.",
  "label.text.createProject.dialog.primaryText": "Yes, leave",
  "label.text.createProject.dialog.secondaryText": "No, Stay",
  "label.text.createProject.success.description1":
    "You can go back to Projects while I finish syncing the connections.\nOnce the project status turns to “Ready” you can start your first session.",
  "label.text.createProject.success.noConnectionsDescription":
    "The project is setup and no connections were added. You must add connections before starting a session.",
  "label.text.createProject.disabledFieldWarning":
    "This field isn't editable right now. Please double-check to ensure you're entering the correct information. Edit functionality will be available soon!",

  //-------------Edit Project-------------//
  "label.text.editProject.editProject": "Edit Project",
  "label.text.editProject.editConnections": "Edit Connections",
  "label.text.editProject.successContainer.projectEdit": "Project-{{name}} successfully edited!",
  "label.text.editProject.disabledFieldWarning":
    "This field can't be edited right now. To make changes, you can start a new project. Edit functionality will be available soon!",

  //-------------Project connect data-------------//
  "label.text.connectData.title": "Create a Project",
  "label.text.connectData.dialog.interpretation": "Hawkeye's interpretation",
  "label.text.connectData.resourceVisualization": "Resource Visualization",

  //-------------Session-------------//
  "label.text.session.button.newSession": "New Session",
  "label.text.session.sessions": "Sessions",
  "label.text.session.noData": "No sessions here... yet",

  //-------------X-ray-------------//
  "label.text.xray.title": "Thought X-Ray",
  "label.text.xray.prompt": "Prompt",
  "label.text.xray.filterChain": "Filter Chain: {{filterChainName}}",
  "label.text.xray.promptExpansionEnvelope": "Envelope",
  "label.text.xray.placeholderText":
    "Click on any of the filter nodes or edges to view its content",

  //-------------Prompt-------------//
  "label.text.prompt.button.newPrompt": "New Prompt",
  "label.text.prompt.prompts": "Prompts",

  //-------------Dashboard-------------//
  "label.text.dashboard.title": "Dashboard",

  //-------------Application resources-------------//

  //-------------Team-------------//
  "label.text.team.title": "Team - {{name}}",
  "label.text.team.title.teams": "Teams",
  "label.text.team.teamMembers": "Team members",
  "label.text.team.onThisTeam": "On this team",
  "label.text.team.button.invite": "Invite members to Hawkeye",
  "label.text.team.button.inviteOrgMembers": "Invite Org members to Hawkeye",
  "label.text.team.card.newTeam": "New Team",
  "label.text.team.stepper.setUpProject": "Set up project",
  "label.text.team.stepper.selectConnections": "Select connections",
  "label.text.team.createTeam.title": "Create Team",
  "label.text.team.createTeam.teamName": "Team Name*",
  "label.text.team.createTeam.description": "Description",
  "label.text.team.createTeam.inYourOrg": "In your org",
  "label.text.team.createTeam.success.title": "Team created!",
  "label.text.team.createTeam.success.addNewTeam": "Add new team",
  "label.text.team.createTeam.button.addToProject": "Add to Project",
  "label.text.team.createTeam.button.back": "Back to Teams",
  "label.text.team.createTeam.cancelDialog.title": "Cancel team",
  "label.text.team.createTeam.cancelDialog.description":
    "Are you sure you want to cancel the team?",
  "label.text.team.createTeam.inviteDialog.title": "Invite members",
  "label.text.team.createTeam.inviteDialog.content":
    "You can share this link to invite anyone in your org on Hawkeye.",
  "label.text.team.createTeam.inviteDialog.button.copyLink": "Copy link",
  "label.text.team.createTeam.inviteDialog.button.linkCopied": "Invite link sent",

  //-------------Users-------------//
  "link.text.users.title": "Users",
  "label.text.users.button.invite": "Invite users to Hawkeye",
  "label.text.users.inviteDialog.title": "Invite users",
  "label.text.users.inviteDialog.content":
    "You can share this link to invite anyone in your org on Hawkeye.",
  "label.text.userList.toast.message":
    "User info has been moved to bin. It will automatically be deleted after 30days",
  "label.text.users.breadCrumb.home": "Home",
  "label.text.userList.delete.title": "Are you sure?",
  "label.text.userList.delete.description":
    "User will be removed from the project and will have to be invited again.",
  "label.text.users.editDialog.title": "Edit user",

  //-------------Reports-------------//
  "label.text.reports.title": "Reports",

  //-------------User settings-------------//
  "label.text.userSettings.title": "User Settings",
  "label.text.userSettings.profile": "Profile",
  "label.text.userSettings.firstName": "First Name",
  "label.text.userSettings.lastName": "Last Name",
  "label.text.userSettings.contact": "Contact",
  "label.text.userSettings.email": "Email",
  "label.text.userSettings.phone": "Phone",
  "label.text.userSettings.password": "Password",
  "label.text.userSettings.forgotPassword": "Forgot Password?",
  "label.text.userSettings.changePassword": "Change Password",
  "label.text.userSettings.password.message":
    "Please enter your current password to change your password",
  "label.text.userSettings.password.currentPassword": "Current Password",
  "label.text.userSettings.password.newPassword": "New Password",
  "label.text.userSettings.password.confirmPassword": "Confirm New Password",
  "label.text.userSettings.breadcrumb.home": "Home",

  //-------------Backend status for cards-------------//
  "label.text.status.error": "Error",
  "label.text.status.insufficient": "Insufficient",
  "label.text.status.incomplete": "Incomplete",
  "label.text.status.initializing": "Initializing",
  "label.text.status.syncing": "Syncing",
  "label.text.status.synced": "Synced",
  "label.text.status.training": "Training",
  "label.text.status.trained": "Trained",
  "label.text.status.unknown": "Unknown",
  "label.text.status.ready": "Ready",
  "label.text.status.loading": "Loading",
  "label.text.status.unspecified": "Unspecified",
  "label.text.status.warning": "Warning",
  "label.text.status.empty": "Empty",

  //-------------Timeline component-------------//
  "label.text.timelineComponent.tooltip.expanded": "Expand timeline view",
  "label.text.timelineComponent.tooltip.collapsed": "Collapse timeline view",

  //-------------Invite User-------------//
  "label.text.inviteUserDialog.title": "Generate Invite",
  "label.text.inviteUserDialog.description":
    "You can invite anyone in your organization on Hawkeye. Enter details to generate invitation link",
  "label.button.inviteUserDialog.generateLink": "Send Invite",
  "label.button.inviteUserDialog.copyLink": "Copy Link",
  "label.button.inviteUserDialog.linkSent": "Link sent",
  "label.button.inviteUserDialog.resetDetails": "Reset Details",

  //-------------Forgot Password-------------//
  "label.text.forgotPassword.title": "Forgot your password?",
  "label.text.forgotPassword.description":
    "Don't worry. Enter your registered email and you'll receive instructions to reset your password.",
  "label.text.forgotPassword.button.resetLink": "Send Reset Link",
  "label.text.forgotPassword.button.backToLogin": "Back to Login",

  //-------------Reset Password-------------//
  "label.text.resetPassword.title": "Create new password",
  "label.text.resetPassword.description":
    "Enter your new password for email {{email}} (make it memorable!)",
  "label.text.resetPassword.newPassword": "New password",
  "label.text.resetPassword.confirmPassword": "Confirm password",
  "label.text.resetPassword.button.resetPassword": "Reset Password",
  "label.text.resetPassword.successContainer.title": "Password reset successfully!",
  "label.text.resetPassword.successContainer.description":
    "You can now use your new password to log in into your account.",
};

export default ALL_CONST;
